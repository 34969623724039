import classNames from "classnames";
import IntrinsicAttributes = React.JSX.IntrinsicAttributes;
import React from "react";

export default function GenericInput({title, className, iconUrl, placeholder, id, name, type, required, pattern}: GenericInputProps) {
	return (
		<input
			placeholder={placeholder}
			id={id}
			name={name}
			type={type}
			required={required || false}
			pattern={pattern}
			className={classNames([`bg-${iconUrl}`,` h-12 xl:h-15 text-sm rounded-lg border-2 border-[#e8e8e8] text-text_dark ${iconUrl ? `bg-no-repeat bg-base bg-left-5-center pl-14 pr-2` : "bg-white px-2"}  ${className}`])}>
			{title}
		</input>
	)
}

interface GenericInputProps extends IntrinsicAttributes , React.ClassAttributes<HTMLInputElement> , React.InputHTMLAttributes<HTMLInputElement> {
	title?: string
	className?: string
	iconUrl?: string,
	placeholder?: string,
	id?: string
	name?: string,
	type?: string,
	required?: boolean
}