import {GenericContainer} from "@/components/generic/GenericContainer";
import GenericWhiteButton from "@/components/generic/GenericWhiteButton";

export function TryNowComponent({title, subtitle, buttonTitle, buttonHref, description}: TryNowComponentProps) {

	return (
		<GenericContainer className={"bg-try-now-mobile-bg xl:bg-try-now-bg bg-no-repeat bg-100% justify-center justify-items-center text-center grid gap-5 xl:gap-8 min-h-[344px]"}>
			<div>
			{title &&
			<p className={"text-white text-base md:text-2xl xl:text-3xl font-semibold md:whitespace-pre-wrap whitespace-normal"}>
				{title}
			</p>}
			{subtitle &&
				<p className={"text-white text-base xl:text-xl font-semibold "}>
					{subtitle}
				</p>}
			</div>
			{description &&
				<p className={"text-white text-sm md:text-base font-light md:whitespace-pre-line"}>
					{description}
				</p>}
			{buttonTitle && buttonHref &&
			<GenericWhiteButton title={buttonTitle} href={buttonHref} className={"w-52"}/>}
		</GenericContainer>
	)
}

interface TryNowComponentProps {
	title?: string,
	subtitle?: string,
	description?: string,
	buttonTitle?: string,
	buttonHref?: string
}