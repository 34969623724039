import classNames from "classnames";

export default function GenericTextArea({title, className, iconUrl, placeholder, id, name, required}: GenericInputProps) {
	return (
		<textarea
			id={id}
			name={name}
			placeholder={placeholder}
			required={required || false}
			className={classNames([`bg-${iconUrl}`,`min-h-[3.75rem] h-40 text-sm  rounded-lg border-2 border-[#e8e8e8] text-text_dark py-4.5 ${iconUrl ? `bg-no-repeat bg-base bg-left-5 pl-14 pr-2` : "bg-white px-2"}  ${className}`])}>
	{title}
	</textarea>
	)
}

interface GenericInputProps {
	title?: string
	className?: string
	iconUrl?: string,
	placeholder?: string,
	id?: string
	name?: string,
	required?: boolean
}