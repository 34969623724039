'use client'
import classNames from "classnames";
import IntrinsicAttributes = React.JSX.IntrinsicAttributes;
import React, {useEffect, useState} from "react";
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'

export default function GenericPhoneInput({
	                                          title,
	                                          className,
	                                          iconUrl,
	                                          placeholder,
	                                          id,
	                                          name,
	                                          type,
	                                          required,
	                                          pattern
                                          }: GenericInputProps) {
	const [phone, setPhone] = useState('');

	return (
		<div>
			<PhoneInput
				id={id}
				placeholder={placeholder}
				value={phone}
				onChange={setPhone}
				defaultCountry="US"
				numberInputProps={{
					className:"h-12 xl:h-15 text-sm  rounded-r-lg border-2 border-[#e8e8e8] -mr-0.5 px-4 ml-2"

				}}
				className={classNames([`pl-5 h-12 xl:h-15 text-sm rounded-lg border-2 border-[#e8e8e8] text-text_dark bg-white px-2}  ${className}`])}
			/>
		</div>
	)
}

interface GenericInputProps extends IntrinsicAttributes, React.ClassAttributes<HTMLInputElement>, React.InputHTMLAttributes<HTMLInputElement> {
	title?: string
	className?: string
	iconUrl?: string,
	placeholder?: string,
	id?: string
	name?: string,
	type?: string,
	required?: boolean
}
