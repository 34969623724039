export default function BlueSubmitButton({title, className, disabled}: GenericButtonProps) {
	return (
		<button
			disabled={disabled || false}
			type={"submit"}
			className={`rounded-full bg-formica_dark text-white font-medium min-w-max px-5 pt-[5px] xl:pt-[8px] pb-[10px] text-lg h-10 xl:h-11  hover:bg-formica_dark/90 ${className}`}>
			{title}
		</button>
	)
}

interface GenericButtonProps {
	title?: string
	className?: string,
	disabled?: boolean
}