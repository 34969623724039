import classNames from "classnames";

export default function SocialMediaIconGroup({containerClassName, iconClassName}: SocialMediaIconGroupProps) {
	return <div className={classNames("flex flex-row gap-3 md:hidden xl:flex", containerClassName)}>
		<a href={"https://www.linkedin.com/company/formica-platform/"}
		   target="_blank" rel="noopener noreferrer"
		   className={classNames("w-11 h-11 bg-contain bg-linkedin bg-no-repeat rounded-full hover:bg-linkedin-hover", iconClassName)}/>
		<a href={"https://www.instagram.com/formica.ai/"}
		   target="_blank" rel="noopener noreferrer"
		   className={classNames("w-11 h-11 bg-contain bg-instagram bg-no-repeat rounded-full hover:bg-instagram-hover", iconClassName)}/>
		<a href={"https://www.youtube.com/@formica4066"}
		   target="_blank" rel="noopener noreferrer"
		   className={classNames("w-11 h-11 bg-contain bg-youtube bg-no-repeat rounded-full hover:bg-youtube-hover", iconClassName)}/>
		<a href={"https://twitter.com/formica_ai"}
		   target="_blank" rel="noopener noreferrer"
		   className={classNames("w-11 h-11 bg-contain bg-x bg-no-repeat rounded-full hover:bg-x-hover", iconClassName)}/>
		<a href={"https://www.facebook.com/formica.platform/"}
		   target="_blank" rel="noopener noreferrer"
		   className={classNames("w-11 h-11 bg-contain bg-facebook bg-no-repeat rounded-full hover:bg-facebook-hover", iconClassName)}/>
	</div>;
}

export interface SocialMediaIconGroupProps {
	containerClassName?: string;
	iconClassName?: string;
}