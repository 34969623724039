'use client';
import Image from "next/image";
import { GenericContainer } from "@/components/generic/GenericContainer";
import GenericInput from "@/components/generic/GenericInput";
import GenericTextArea from "@/components/generic/GenericTextArea";
import { ChangeEvent, FormEvent, useState } from "react";
import BlueSubmitButton from "@/components/contact-us/BlueSubmitButton";
import { MailService } from "@/service/MailService";
import GenericPhoneInput from "@/components/generic/GenericPhoneInput";
import {useTranslations} from "next-intl";

export default function ContactUsComponent({ containerClassName }: ContactUsComponentProps) {
	const [status, setStatus] = useState<Status | undefined>(undefined);
	const [message, setMessage] = useState<string | undefined>(undefined);
	const mailService = new MailService();
	const t = useTranslations('');

	const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		const { email, firstname, lastname, jobtitle, newsletter, message, tel, country } = e.currentTarget.elements as any;
		const formData: ContactUsFormData = {
			email: email.value,
			firstname: firstname.value,
			lastname: lastname.value,
			jobtitle: jobtitle.value,
			newsletter: newsletter.checked,
			value: message.value,
			tel: tel.value,
			country: country.value
		}
		mailService.sendContactUsMail(formData).then(r => {
			setMessage(t("contactUs.form.successMessage"));
			setStatus(Status.SUCCESS);
		}).catch(e => {
			setMessage(t("contactUs.form.errorMessage"));
			setStatus(Status.ERROR);
		})
	}

	return (
		<GenericContainer
			id={"contact-us"}
			className={`scroll-mt-[80px] min-h-max px-32 h-[600px] flex flex-col xl:flex-row justify-center items-center gap-x-12 bg-white ${containerClassName}`}>
			{
				status == Status.SUCCESS &&
				<div className={"flex flex-col w-full justify-center items-center text-center xl:text-start"}>
					<div className={"mb-5 flex flex-col items-center"}>
						<Image src={"/logo/Logo_black.svg"} alt={"Formica Logo"} width={140} height={34} />
						<p className={"text-xl font-medium text-text_dark text-center pt-9"}>
							{<div dangerouslySetInnerHTML={{ __html: message as TrustedHTML }} /> || t("contactUs.form.successMessage")}
						</p>
					</div>
				</div>
			}
			{(status == undefined || status == Status.ERROR || status == Status.LOADING) &&
				<>
					<div className={"flex flex-col max-w-[430px] justify-center text-center xl:text-start"}>
						<div className={"mb-5 flex items-center xl:items-start flex-col"}>
							<Image src={"/logo/Logo_black.svg"} alt={"Formica Logo"} width={140} height={32} />
							<p className={"text-base xl:text-xl font-medium text-text_dark pt-3 xl:pt-9"}>
								{t("contactUs.description")}
							</p>
						</div>
					</div>
					<form className={"gap-3 xl:gap-6 flex flex-col max-w-[430px] md:max-w-[768px] xl:max-w-[430px] text-sm xl:text-base"} onSubmit={handleSubmit}>
						<div className={"grid md:grid-cols-2 gap-y-3 gap-x-3"}>
							<GenericInput type={"text"} id={"firstname"} name={"firstname"} iconUrl={"name-icon"}
							              placeholder={t("contactUs.form.firstName")} required />
							<GenericInput type={"text"} id={"lastname"} name={"lastname"} iconUrl={"name-icon"}
							              placeholder={t("contactUs.form.lastName")} required />
							<GenericInput type={"email"} id={"email"} name={"email"} iconUrl={"email-icon"}
							              placeholder={t("contactUs.form.email")} required />
							<GenericInput type={"text"} id={"jobtitle"} name={"jobtitle"} iconUrl={"job-title-icon"}
							              placeholder={t("contactUs.form.jobTitle")} required />
							<GenericPhoneInput type={"tel"} id={"tel"} name={"tel"} iconUrl={"phone-icon"}
							                   placeholder={t("contactUs.form.phone")} />
							<GenericInput type={"country"} id={"country"} name={"country"} iconUrl={"country-icon"}
							              placeholder={t("contactUs.form.country")} required />
						</div>
						<GenericTextArea id={"message"} name={"message"} iconUrl={"enter-message-icon"}
						                 placeholder={t("contactUs.form.message")} required />
						<p className={"text-text_light font-light"}>
							{t.rich("contactUs.form.privacyPolicyMessage", {
								"privacyPolicy": (chunks) => {
									return <a href={"/privacy-policy"} className={"text-formica"}>{t(chunks[0])}</a>;
								}
							})}
						</p>
						<div className={"flex items-center gap-x-2 text-text_dark"}>
							<input type="checkbox" id="newsletter" name="Newsletter" className={"w-4 h-4"} defaultChecked />
							<label htmlFor="newsletter">{t("contactUs.form.newsletter")}</label>
						</div>
						<BlueSubmitButton title={t("contactUs.form.submitButton")} className={"text-center"}
						                  disabled={status == Status.LOADING} />
						{status == Status.ERROR &&
							<p className={"text-text_light font-light text-xl py-6"}>
								{message || t("contactUs.form.errorMessage")}
							</p>}
					</form>
				</>
			}
		</GenericContainer>
	)
}

export interface ContactUsComponentProps {
	containerClassName?: string
}

export interface ContactUsFormData {
	email: string,
	firstname: string,
	lastname: string,
	jobtitle: string,
	newsletter: boolean,
	value: string,
	tel: string,
	country: string
}

enum Status {
	SUCCESS,
	ERROR,
	LOADING
}
